<template>
  <!-- <v-container fluid> -->
  <v-container class="pa-10" fluid>
    <v-snackbar top :timeout="5000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <h3 class="ml-5" style="color: #ff1544">Cambiar contraseña</h3>
    <!-- <v-form ref="form" lazy-validation @keyup.enter.native="onNext"> -->
    <v-row class="section">
      <v-col cols="12" sm="4" md="3"
        ><p>Contraseña anterior</p>
        <v-text-field
          v-model="password.old"
          :rules="[rules.required, rules.min]"
          autocomplete="new-password"
          :loading="loading"
          :type="show ? 'text' : 'password'"
          rounded
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3"
        ><p>Nueva contraseña</p>
        <v-text-field
          v-model="password.new"
          :rules="[rules.required, rules.min]"
          :type="show ? 'text' : 'password'"
          autocomplete="new-password"
          rounded
          outlined
          :loading="loading"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3"
        ><p>Confirmar contraseña</p>
        <v-text-field
          v-model="password.confirm"
          :type="show ? 'text' : 'password'"
          autocomplete="new-password"
          :rules="[rules.required, rules.min]"
          rounded
          outlined
          :loading="loading"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="1" md="1" class="mt-5">
        <v-icon @click="show = !show" style="cursor: pointer" class="mt-10">
          {{ show ? "fas fa-eye-slash" : "fas fa-eye" }}
        </v-icon>
      </v-col>
      <v-col cols="12" sm="4" md="2"
        ><v-btn
          id="sign-in-button"
          :loading="loading"
          color="primary"
          block
          height="45"
          style="border-radius: 15px"
          class="mt-10"
          >Cambiar</v-btn
        >
      </v-col>
    </v-row>
    <!-- </v-form> -->

    <h3 class="ml-5 mt-10" style="color: #ff1544">
      MFA Multifactor de Autenticación
    </h3>
    <v-row class="section">
      <v-col cols="12" sm="10" md="10">
        <p class="mt-3" v-if="!emailVerified">
          Para activar el factor de doble autenticación es necesario verificar
          su correo electrónico.
        </p>
        <p v-else class="mt-3">
          MFA - Multifactor de Autenticación
          {{ user.mfa ? "activo" : "desactivado" }}
        </p>
      </v-col>

      <v-col cols="12" sm="2" md="2">
        <v-btn
          absolute
          @click="sendVerificationEmail"
          :loading="sendingEmail"
          color="green"
          class="white--text mt-2"
          v-if="!emailVerified"
        >
          VERIFICAR EMAIL
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          :color="user.mfa ? 'red' : 'green'"
          block
          @click="user.mfa ? disableMFA() : showMFA()"
          class="white--text"
          height="45"
          style="border-radius: 10px"
        >
          {{ user.mfa ? "Desactivar" : "Activar" }}</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog persistent v-if="mfaDialog" v-model="mfaDialog" max-width="500px">
      <mfa @success="mfaSuccess" @cancel="mfaDialog = false" />
    </v-dialog>
    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp
        :auth="authObject"
        ref="code"
        @cancel="otpDialog = false"
        @success="otpConfirmed"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-if="disableMfaDialog"
      v-model="disableMfaDialog"
      max-width="500px"
    >
      <disable-mfa
        @success="disableMFAConfirmed"
        ref="disMfa"
        @cancel="disableMfaDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { fb } from "@/firebase";
import { mapState } from "vuex";
import mfa from "./mfa";
import disableMfa from "./disableMFA";
import otp from "./insertCode.vue";

export default {
  name: "profile",
  components: {
    mfa,
    disableMfa,
    otp,
  },
  data() {
    return {
      loading: true,
      snackbar: "",
      snackbarText: "",
      loading: false,
      show: false,
      mfaDialog: false,
      disableMfaDialog: false,
      emailVerified: true,
      sendingEmail: false,
      authObject: null,
      otpDialog: false,
      verificationId: null,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      },
      password: {
        old: "",
        new: "",
        confirm: "",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async otpConfirmed(otp) {
      try {
        var cred = await fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          otp
        );

        var multiFactorAssertion =
          await fb.auth.PhoneMultiFactorGenerator.assertion(cred);

        await this.authObject.resolveSignIn(multiFactorAssertion);

        const user = fb.auth().currentUser;
        await user.updatePassword(this.password.new);

        this.snackbarText = "Contraseña actualizada exitosamente.";
        this.snackbar = true;
        this.loading = false;

        fb.auth().signOut();
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-verification-code":
            this.$refs.code.error();

            break;

          default:
            break;
        }
      }
    },
    async mfaValidation() {
      var phoneInfoOptions = {
        multiFactorHint: this.authObject.hints[0],
        session: this.authObject.session,
      };

      try {
        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        this.otpDialog = true;
      } catch (error) {
        recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
      }
    },
    showMessage(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
      grecaptcha.reset(window.recaptchaWidgetId);
    },
    showMFA() {
      if (!this.user.mfa) {
        this.mfaDialog = true;
      }
    },

    disableMFAConfirmed() {
      this.snackbarText = "MFA desactivado correctamente.";
      this.snackbar = true;
      this.disableMfaDialog = false;
    },

    disableMFA() {
      this.disableMfaDialog = true;
    },

    mfaSuccess() {
      this.snackbarText = "MFA activado correctamente.";
      this.snackbar = true;
      this.mfaDialog = false;
    },

    sendVerificationEmail() {
      this.sendingEmail = true;
      fb.auth()
        .currentUser.sendEmailVerification()
        .then(() => {
          this.snackbarText = `Se ha enviado un correo de confirmación a la dirección ${this.user.email}`;
          this.snackbar = true;
          this.sendingEmail = false;
        });
    },
    async change() {
      if (this.password.new && this.password.old && this.password.confirm) {
        try {
          if (this.password.new != this.password.confirm) {
            this.showMessage("Las nuevas contraseñas no coinciden.");
            return;
          }

          this.loading = true;

          const user = fb.auth().currentUser;

          let credential = fb.auth.EmailAuthProvider.credential(
            this.user.email,
            this.password.old
          );

          await user.reauthenticateWithCredential(credential);
          await user.updatePassword(this.password.new);

          this.snackbarText = "Contraseña actualizada exitosamente.";
          this.snackbar = true;
          this.loading = false;

          fb.auth().signOut();
        } catch (error) {
         console.log(error);
          switch (error.code) {
            case "auth/multi-factor-auth-required":
              this.authObject = error.resolver;
              this.mfaValidation();
              break;

            case "auth/weak-password":
              this.showMessage(
                "La nueva contraseña es muy débil, ingrese una contraseña alfanumérica mayor a 8 dígitos."
              );
              break;
            case "auth/wrong-password":
              this.showMessage("La contraseña actual ingresada es incorrecta.");
              break;

            case "auth/too-many-requests":
              this.showMessage(
                "El acceso a esta cuenta ha sido deshabilitado temporalmente... configure su contraseña o puede intentarlo de nuevo más tarde."
              );
              break;

            default:
              this.showMessage(error.message);

              break;
          }

          this.snackbar = true;
          this.loading = false;
        }
      } else {
        this.snackbarText = "Debe completar todos los campos.";
        this.snackbar = true;
      }
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;

    this.emailVerified = fb.auth().currentUser.emailVerified;
    window.recaptchaVerifier = new fb.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
      callback: async () => {
        this.change();
      },
    });

    recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  },
};
</script>

<style scoped>
.section {
  background-color: rgba(177, 177, 177, 0.195);
  padding: 10px;

  border-radius: 10px;

  margin: 15px;
}

.enabled {
  background-color: red;
}

.disabled {
  background-color: green;
}
</style>
